import React from 'react';
import { StaticQuery, graphql } from "gatsby";
import Home from "../components/Home";

export default function home() {
    return (
        <StaticQuery
            query={graphql`
            query getHomeContent {
                allNew(sort: {order: ASC, fields: index}) {
                    edges {
                        node {
                            title
                            img
                            index
                            text
                            link
                            button
                        }
                    }
                }
                allPhoto(sort: {fields: index, order: ASC}) {
                    edges {
                        node {
                            img
                            index
                        }
                    }
                  }
            }`}
            render={data => ( <Home data={data} /> )}
        />
    )
}
