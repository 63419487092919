import React, { useState, useEffect } from 'react';
import Trazado733Mobile from "../../assets/images/page_Home/TrazadoMobile 733.png";
import TrazadoMobile744 from "../../assets/images/page_Home/TrazadoMobile 744.png";
import TrazadoMobile9 from "../../assets/images/page_Home/TrazadoMobile 9.png";
import Trazado733 from "../../assets/images/page_Home/Trazado 733.png";
import Trazado680 from "../../assets/images/page_Home/Trazado 680.png";
import VinoRosado from "../../assets/images/page_Home/VinoRosado.png";
import VinoBlanco from "../../assets/images/page_Home/VinoBlanco.png";
import Elipse233 from "../../assets/images/page_Home/Elipse 233.png";
import VinoTinto from "../../assets/images/page_Home/VinoTinto.png";
import Blasfemia from "../../assets/images/page_Home/Blasfemia.png";
import Trazado9 from "../../assets/images/page_Home/Trazado 9.png";
import Trazado8 from "../../assets/images/page_Home/Trazado 8.png";
import Elipse17 from "../../assets/images/page_Home/Elipse 17.png";
import Elipse18 from "../../assets/images/page_Home/Elipse 18.png";
import Elipse16 from "../../assets/images/page_Home/Elipse 16.png";
import Elipse19 from "../../assets/images/page_Home/Elipse 19.png";
import Elipse20 from "../../assets/images/page_Home/Elipse 20.png";
import Elipse21 from "../../assets/images/page_Home/Elipse 21.png";
import Elipse39 from "../../assets/images/page_Home/Elipse 39.png";
import Grupo760 from "../../assets/images/page_Home/Grupo 760.png";
import DSC_1687 from "../../assets/images/page_Home/DSC_1687.png";
import DSC_1697 from "../../assets/images/page_Home/DSC_1697.png";
import DSC_1741 from "../../assets/images/page_Home/DSC_1741.png";
import DSC_1856 from "../../assets/images/page_Home/DSC_1856.png";
import Elipse1 from "../../assets/images/page_Home/Elipse 1.png";
import Elipse2 from "../../assets/images/page_Home/Elipse 2.png";
import Elipse3 from "../../assets/images/page_Home/Elipse 3.png";
import Elipse4 from "../../assets/images/page_Home/Elipse 4.png";
import Elipse5 from "../../assets/images/page_Home/Elipse 5.png";
import Elipse6 from "../../assets/images/page_Home/Elipse 6.png";
import Elipse7 from "../../assets/images/page_Home/Elipse 7.png";
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import burga from "../../assets/images/page_Home/burga.png";
import { Container, Image, Row } from 'react-bootstrap';
import 'react-alice-carousel/lib/alice-carousel.css';
import FooterPage from "../components/FooterPage";
import AliceCarousel from 'react-alice-carousel';
import Header from "../components/Header";
import AOS from 'aos';
import 'aos/dist/aos.css';
import "../styles/Home.css";

export default function Home({ data }) {
    const news = data.allNew.edges.map(n => n.node),
    images = data.allPhoto.edges.map((p, i) => <img  src={p.node.img}  className={i %2 === 0  ?  "imgHomePar" : "imgHomeImpar" } onDragStart={e => e.preventDefault()} alt="" />),
    [imageHover, setImageHover] = useState(false);
  

    let AOS;
  useEffect(() => {
    const AOS = require("aos");
    AOS.init({
      once: true,
    });
  }, []);

  useEffect(() => {
    if (AOS) {
      AOS.refresh();
    }
  });

    return (
        <div className="bgcolor">
            <div className="bghome">
                <div className="homeheight">
                    <Image src={Trazado9} className="cropimaget9 d-none d-md-flex" alt="" />
                    <Image src={TrazadoMobile9} className="cropimagemobilet9 d-md-none" alt="" />
                    <Image src={Trazado733Mobile} className="cropimagemobilet7 d-md-none" alt="" />
                    <Image src={Trazado733} className="cropimaget7 d-none d-md-flex" alt="" />
                    <Image src={Trazado8} className="cropimaget8 d-none d-md-flex" alt="" />
                    <Image src={Elipse5} className="elipse5 d-none d-md-flex" alt="" />
                    <Image src={Elipse3} className="elipse3" alt="" />
                    <Image src={Elipse1} className="elipse1 d-none d-md-flex" alt="" />
                    <Image src={Elipse2} className="elipse2" alt="" />
                    <Image src={Elipse4} className="elipse4 d-none d-md-flex" alt="" />
                    <Header />
                    <Container fluid >
                        <Image src={burga} className="firstimage" />
                        <Row>
                            <h1 className="firsttitle">Llegó un vino <br/> para <span className="colorpink">cambiar</span> <br/> las <span className="colorpink">reglas</span></h1>
                            <p className="firstsubtitle d-none d-md-block">Pragmátco, espontaneo, independiente, con sentido <br/> del humor ácido e inteligente. Así es Blasfemia, en <br/> ciertos espacios puede resultar un tanto incómodo y <br/> estamos orgullosxs que así sea.</p>
                            <p className="firstsubtitle d-flex d-md-none">Pragmátco, espontaneo, independiente, <br/> con sentido del humor ácido e inteligente. <br/> Así es Blasfemia, en ciertos espacios <br/> puede resultar un tanto incómodo y <br/> estamos orgullosxs que así sea.</p>
                            <button onClick={() => window.location.replace("https://www.craftsociety.com.ar/pages/search-results?q=blasfemia")} className="buttonblasf">COMPRAR</button>
                        </Row>
                    </Container>
                    <Image src={Elipse18} className="elipse18 d-none d-md-flex" alt="" />
                    <Image src={Elipse16} className="elipse16 d-flex d-md-none" alt="" />
                </div>
                <Container fluid>
                    <Image src={VinoBlanco} onMouseEnter={() => setImageHover(true)} className={imageHover ? "secondimage1hover" : "secondimage1" } onMouseLeave={() => setImageHover(false)} alt="" />
                    <Image src={VinoRosado} onMouseEnter={() => setImageHover(true)} className={imageHover ? "secondimage2hover" : "secondimage2" } onMouseLeave={() => setImageHover(false)} alt="" />
                    <h1 className="secondtext"> Nosotrxs somos</h1>
                    <Row className="d-flex flex-column  align-content-center text-center">
                        <div className="">
                            <Image src={Blasfemia}  alt="Blasfemia" className="secondimage" />
                        </div>
                    </Row>
                    <Row className="d-flex flex-column align-content-start align-content-md-end">
                        <Image src={VinoTinto} onMouseEnter={() => setImageHover(true)} className={imageHover ? "secondimage3hover" : "secondimage3" } onMouseLeave={() => setImageHover(false)} alt=""/>
                        <Image src={Elipse16} className="elipse161 d-flex d-md-none" alt="" />
                        <h1 className="secondtitle">Vino en lata, <br/> práctico y fácil  <br/> de tomar</h1>
                        <p className="secondsubtitle">Sin rituales ni descripciones de varietales <br/> complejas.</p>
                        <div>
                            <button className="buttonnosotrxs">Nuestros vinitos</button>
                        </div>
                    </Row>
                </Container>
                <Image src={Elipse6} className="elipse6 d-none d-md-flex" alt="" />
                <Image src={Elipse7} className="elipse7 d-none d-md-flex" alt="" />
                <Image src={Elipse17} className="elipse17 d-none d-md-flex" alt="" />
                <Image src={Elipse20} className="elipse20 d-none d-md-flex" alt="" />
                <Image src={DSC_1741} data-sal="fade-in" data-sal-delay="500" data-sal-easing="ease-in-out" className="picture1741 d-none d-md-flex" alt="" />
                <Image src={Elipse19} className="elipse19 d-none d-md-flex" alt="" />
                <Image src={DSC_1687} data-sal="fade-in" data-sal-delay="500" data-sal-easing="ease-in-out" className="picture1687 d-none d-md-flex" alt="" />
                <Image src={DSC_1856}className="picture1856 " alt="" />
                <Image src={Grupo760} className="grupo760" alt="" />
                <Image src={Trazado680} className="trazado680" alt="" />
                <Image src={DSC_1697} data-sal="fade-in" data-sal-delay="500" data-sal-easing="ease-in-out" className="picture1697 d-none d-md-flex" alt="" />
                <Container fluid>
                    <Row className="d-flex flex-column text-center">
                        <h1 className="thirdtitle d-none d-md-flex">Dónde conseguir <br/> tus vinos</h1>
                        <h1 className="thirdtitle d-flex d-md-none">Dónde <br/> conseguir <br/> tus vinos</h1>
                        <p className="thirdsubtitle d-none d-md-flex">Conocé los puntos de venta donde podés <br/> conseguir Blasfemia.</p>
                        <p className="thirdsubtitle d-flex d-md-none">Conocé los puntos de venta donde  <br/> podés conseguir Blasfemia.</p>
                        <div>
                            <button className="buttonmap">Ver en Mapa</button>
                        </div>
                    </Row>
                <Image src={Elipse21} className="elipse21 d-none d-lg-flex" alt="" />
                </Container>
                <Container fluid>
                    <Image src={Elipse233} className="elipse233 d-flex d-lg-flex" alt="" />
                    <Image src={TrazadoMobile744} className="trazadomobile744 d-md-none" alt="" />
                    <Image src={Elipse39} className="elipse39 d-flex d-md-none" alt="" />
                    <Row className="d-flex flex-column">
                        <h1 className="fourtitle">Seamos amigxs</h1>
                        <p className="foursubtitle"><button onClick={() => window.location.replace("https://www.instagram.com/blasfemia.arg/")} className="buttonbuildingho">seguinos en IG</button></p>
                        <div className="carousel">
                            <AliceCarousel
                                mouseTracking
                                items={images}
                                infinite={true}
                                autoPlay={true}
                                disableSlideInfo={true}
                                disableDotsControls={true}
                                disableButtonsControls={true}
                                autoPlayStrategy={'default'}
                                responsive={{ 0: { items: 2 }, 768: { items: 7 }, 1366: { items: 7 }}}
                            />
                        </div>
                    </Row>
                </Container>
            </div>
            <FooterPage />
        </div>
    );
}